<template>
  <FilterBarBase @onClickSearch="onClickSearch" @onClickReset="onClickReset">
    <dl class="w152">
      <dt>진행 상태</dt>
      <dd>
        <SelectBox class="w152" :dataList="statusList" :value.sync="stateId" />
      </dd>
    </dl>
    <dl>
      <dt>평가일자</dt>
      <dd>
        <DateRangeQuickSelect :dates.sync="dates" />
      </dd>
      <dt>분류</dt>
      <dd>
        <CategoryGroupIncludeData
          :category0.sync="category0"
          :category1.sync="category1"
          :category2.sync="category2"
        />
      </dd>
      <!-- <dt>진행상태</dt>
      <dd>
        <Radio name="filterStatus"
            :dataList="statusList"
            :selectedId.sync="stateId" /> 
      </dd>       -->
      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <Radio
            name="keywordDatas"
            :dataList="keywordDatas"
            :selectedId.sync="keywordSelectedId"
          />
          <!-- <SelectBox class="w152" :dataList="keywordDatas" :value.sync="keywordSelectedId" /> -->
          <Input class="w336" :value.sync="keywordInputText" @onEnterKey="onClickSearch" />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import Input from "@/components/common/input/Input";
import Radio from "@/components/common/radio/Radio";
import SelectBox from "@/components/common/selectBox/SelectBox";
import CategoryGroupIncludeData from "@/components/common/selectBox/CategoryGroupIncludeData";
import DateRangeQuickSelect from "@/components/common/calendar/DateRangeQuickSelect";
import { mapState } from "vuex";

import LocalStorageManager from "@/LocalStorageManager";

import FilterBarMixin from "@/mixins/FilterBarMixin";

export default {
  components: {
    FilterBarBase,
    Input,
    Radio,
    SelectBox,
    DateRangeQuickSelect,
    CategoryGroupIncludeData,
  },
  mixins: [FilterBarMixin],
  props: {
    statusList: Array,
    defaultRadioId: String,
    defaultStatus: String,
    defaultDates: Array,
    defaultStateId: String,
  },
  data() {
    return {
      status: this.defaultStateId,
      selectedId: this.defaultRadioId,
      dates: this.defaultDates,
      category0: null,
      category1: null,
      category2: null,
    };
  },
  // data() {
  //   return {
  //     radioData: {
  //       name: 'bidListRadio0',
  //       dataList: [
  //         { id: '0', text: '계약명' },
  //         { id: '1', text: '구매 담당자' },
  //         { id: '2', text: '현업 담당자' }
  //       ],
  //       selectedId:  this.defaultRadioId,
  //     },
  //     inputText: this.defaultInputText,
  //     status: this.defaultStatus,
  //     dates: this.defaultDates,
  //     category0: null,
  //     category1: null,
  //     category2: null,
  //     statusList:[
  //       { code: '0', desc: '전체', name: '전체', value: '0'},
  //       { code: '1', desc: '평가완료', name: '평가완료', value: '1'},
  //       { code: '2', desc: '현업평가 필요', name: '현업평가 필요', value: '2'},
  //       { code: '3', desc: '구매평가 요청', name: '구매평가 요청', value: '3'},
  //     ],
  //   }
  // },
  methods: {
    onClickSearch() {
      let obj = this.getCommonFilterbarData();

      this.$emit("onClickSearch", obj);
    },

    onClickReset() {
      this.onClickResetFilterBar();
    },
  },
};
</script>
