<template>
  <InputBase :class="{ disabled: isDisabled, tf_error: isError }">
    <input
      ref="input"
      v-model="input"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :maxLength="maxLength"
      @focus="onFocus"
      @blur="onBlur"
      @keyup.13="onKeyUpEnter"
    />
  </InputBase>
</template>

<script>
import InputBase from "@/components/common/input/InputBase";
import { getCanConvertNumber } from "@/utils/numberUtils";
import { addCommaForMoney } from "@/utils/stringUtils";

export default {
  name: "InputAutoMoney",
  components: {
    InputBase,
  },
  props: {
    value: String,
    placeholder: String,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    maxLength: Number,
  },
  computed: {
    input: {
      get() {
        const validText = this.getValidText(this.value);

        if (validText !== this.value) {
          this.$emit("update:value", validText);
        }

        if (this.maxLength && this.value) {
          if (this.value.length > this.maxLength) {
            this.$emit("update:value", this.value.slice(0, this.maxLength));
          }
        }

        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  methods: {
    getValidText(strOrg) {
      if (!strOrg) return "";

      strOrg = strOrg.trim();

      // 그냥 다 숫자면 그대로 리턴.
      if (getCanConvertNumber(strOrg)) {
        return strOrg;
      }

      let ret = "";

      let char;

      for (var i = 0; i < strOrg.length; ++i) {
        char = strOrg.charAt(i);

        if (char != ".") {
          if (!getCanConvertNumber(char)) continue;
        }

        ret += char;
      }

      if (!ret) return "";

      return ret;
    },
    blur() {
      // 외부에서 호출 될 메서드.
      this.$refs.input.blur();
    },
    onFocus(e) {
      this.$emit("update:focus", e);
    },
    onBlur(e) {
      this.$emit("update:blur", e);
    },
    onKeyUpEnter() {
      this.blur();
    },
  },
};
</script>
