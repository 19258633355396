<template>
  <FilterBarBase @onClickSearch="onClickSearch" @onClickReset="onClickReset">
    <dl>
      <dt>점수</dt>
      <dd>
        <div class="group_form">
          <InputScore
            class="w120"
            :value.sync="scoreInputText"
            :maxLength="3"
            placeholder="점수 입력"
            @onEnterKey="onClickSearch"
          />
          <span class="w27 txt_view_r">점</span>
          <SelectBox
            class="w120"
            :dataList="scoreList.dataList"
            :value.sync="scoreList.selectedId"
          />
        </div>
      </dd>
      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <Radio name="filterbar" :dataList="keywordDatas" :selectedId.sync="keywordSelectedId" />
          <Input class="w448" :value.sync="keywordInputText" @onEnterKey="onClickSearch" />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import Input from "@/components/common/input/Input";
import InputScore from "@/components/common/input/InputScore";
import Radio from "@/components/common/radio/Radio";
import SelectBox from "@/components/common/selectBox/SelectBox";
import CategoryGroupIncludeData from "@/components/common/selectBox/CategoryGroupIncludeData";
import DateRangeQuickSelect from "@/components/common/calendar/DateRangeQuickSelect";
import { mapState } from "vuex";

import LocalStorageManager from "@/LocalStorageManager";

import FilterBarMixin from "@/mixins/FilterBarMixin";

export default {
  components: {
    FilterBarBase,
    Input,
    InputScore,
    Radio,
    SelectBox,
    DateRangeQuickSelect,
    CategoryGroupIncludeData,
  },
  mixins: [FilterBarMixin],
  props: {
    // filterbar 추가
    defualtScoreSelectedId: String,
    defaultScoreInputText: String,
    defaultKeywordInputScoreText: String,
  },
  data() {
    return {
      scoreList: {
        dataList: [
          { code: "U", desc: "이상" },
          { code: "D", desc: "이하" },
        ],
        selectedId: "U",
      },
      keywordInputScoreText: this.defaultKeywordInputScoreText,
      keywordScoreSelectedId: this.defualtScoreSelectedId,
      scoreInputText: this.defaultScoreInputText,
    };
  },
  methods: {
    //debouncedQuery: _.debounce(function () { this.query(); }, 300),
    // async query() {
    //   const strScore = String( this.scoreInputText );

    //   if(strScore.length == 1) {
    //     return strScore + '.0';
    //   } else {
    //     return  strScore.substr(0, 1) + '.' + strScore.substr(1,strScore.length - 1);
    //   }
    // },
    onClickSearch() {
      let obj = this.getCommonFilterbarData();

      // if(this.scoreInputText) {
      //   if(this.scoreInputText.indexOf('.') > -1) {
      //     obj.score = this.scoreInputText;
      //   } else {
      //     obj.score = this.addDotForScore(this.scoreInputText);
      //   }
      // }

      if (this.scoreInputText) obj.score = this.scoreInputText;

      if (this.scoreList.selectedId) obj.scoreCondition = this.scoreList.selectedId;

      this.$emit("onClickSearch", obj);
    },

    onClickReset() {
      this.scoreInputText = this.defaultScoreInputText;
      this.scoreList.selectedId = this.defualtScoreSelectedId;
      this.onClickResetFilterBar();
      this.keywordSelectedId = "companyName";
    },
    // addDotForScore( value ) {

    //   const strScore = String( value );

    //   if(value.length == 1) {
    //     return value + '.0';
    //   } else {
    //     return  value.substr(0, 1) + '.' + value.substr(1,value.length - 1);
    //   }
    // },
    // onUpdateScore( value ) {
    //   this.debouncedQuery();
    // }
  },
};
</script>
