const SystemPerformanceMixin = {
  data() {
    return {
      KEY_PARAM: "tab",
      TAB_DATA_LIST: [
        { value: "SYSTEM_PERFORMANCE_SETTING", text: "평가생성" },
        { value: "SYSTEM_PERFORMANCE_PRESENT", text: "평가현황" },
        { value: "SYSTEM_PERFORMANCE_RESULT", text: "수행도평가 결과" },
      ],
      TAB_CONST: {
        SYSTEM_PERFORMANCE_SETTING: "SYSTEM_PERFORMANCE_SETTING",
        SYSTEM_PERFORMANCE_PRESENT: "SYSTEM_PERFORMANCE_PRESENT",
        SYSTEM_PERFORMANCE_RESULT: "SYSTEM_PERFORMANCE_RESULT",
      },
    };
  },
  methods: {
    async onChangeTabValue(value) {
      if (this.$route.path !== this.$routerPath[value]) {
        await this.$router.push({ path: this.$routerPath[value] });
      }
    },
  },
};

export default SystemPerformanceMixin;
