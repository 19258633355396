<template>
  <div class="tab_comm" role="navigation">
    <ul class="list_tab">
      <template v-for="(item, index) in dataList">
        <li v-if="item.isShow !== false" :key="index" :class="{ on: item.value === currentValue }">
          <!-- <router-link :to='toUrl( item.key, item.value )' class="link_tab" :disabled="true"> -->
          <a href="javascript:void(0);" class="link_tab" @click="onClickTab(item.value)">
            {{ item.text }}
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    keyParam: String,
    dataList: Array,
    currentValue: String,
  },
  computed: {
    toUrl() {
      return (value) => {
        const params = {};
        params[this.keyParam] = value;

        let query = Object.assign({}, this.$route.query, params);

        let ret = {
          path: this.$route.path,
          query: query,
        };

        return ret;
      };
    },
  },
  watch: {
    $route(to, from) {
      const toValue = to.query[this.keyParam];

      this.checkQuery(toValue, false);
    },
  },
  mounted() {
    const query = this.$route.query;
    const valueByQuery = query[this.keyParam];

    this.checkQuery(valueByQuery, true);
  },
  methods: {
    checkQuery(toValue, isInit) {
      if (toValue && toValue != this.currentValue) {
        this.$emit("update:currentValue", toValue);

        if (!isInit) {
          this.$emit("onChangeTabValue", toValue);
        }
      }
    },
    onClickTab(toValue) {
      if (toValue != this.currentValue) {
        this.$emit("update:currentValue", toValue);
        this.$emit("onChangeTabValue", toValue);
      }
    },
  },
};
</script>

<style scoped src="./tabs.css"></style>
