<template>
  <ListRow>
    <td>{{ rowData.statusName }}</td>
    <td>{{ rowData.contractDivName }}</td>
    <td class="td_ellip align_left" :data-ellip="rowData.category">
      {{ category }}
    </td>
    <td class="td_ellip" :data-ellip="rowData.inspectManager">
      {{ rowData.requester }}
    </td>
    <td class="td_ellip" :data-ellip="rowData.purchaseManager">
      {{ rowData.purchaseManager }}
    </td>
    <td class="td_ellip align_left" :data-ellip="rowData.description">
      <!-- <router-link :to="toPath" class="link_subject" :data-ellip="rowData.description">
        </router-link> -->
      {{ rowData.description }}
    </td>
    <td class="td_ellip" :data-ellip="rowData.companyName">
      <button @click="onClickPerformance">{{ rowData.companyName }}</button>
    </td>
    <td>{{ rowData.regId }}</td>
    <td>{{ rowData.completeDate | dateStringFormat }}</td>
  </ListRow>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import SelectBox from "@/components/common/selectBox/SelectBox";
import ListLineMixin from "@/mixins/ListLineMixin";
import { makePathWithQuery } from "@/utils/urlUtils";

export default {
  name: "PartnerListApprovalLine",
  components: {
    ListRow,
    SelectBox,
  },
  mixins: [ListLineMixin],
  props: {
    statusList: Array,
  },
  computed: {
    toPath() {
      const { contractId } = this.rowData;
      const path = makePathWithQuery(this.$routerPath.CONTRACT_VIEW, contractId, this.$route.query);

      return path;
    },
    // currentStatusList() {
    //   if( !this.rowData )  return this.statusList;

    //   const ret = this.statusList.map( item => {
    //     // P: 대기, D: 휴면.
    //     if( item.code !== 'P' && item.code !== 'D' ) return item;

    //     const itemP = Object.assign( {}, item, { isDisabled: true } );
    //     return itemP;
    //   } );

    //   return ret;
    // }
    category() {
      const { cate1, cate2, cate3 } = this.rowData;

      let category = "";

      let cateArray = [];
      if (cate1) cateArray.push(cate1);
      if (cate2) cateArray.push(cate2);
      if (cate3) cateArray.push(cate3);

      if (cateArray.length > 0) {
        if (cateArray.length == 1) {
          category = cateArray[0];
        } else if (cateArray.length == 2) {
          category += cate2;
          category += "> " + cate3;
        } else {
          if (cate1) category += cate1;
          if (cate2) category += "> " + cate2;
          if (cate3) category += "> " + cate3;
        }
      }

      // else {
      //   category = '-';
      // }

      return category;
    },
  },
  methods: {
    onChangeStatus(to, from) {
      this.$emit("onChangeStatus", to, from, this.rowData);
    },
    onClickPerformance() {
      this.$emit("onClickPerformance", this.rowData);
    },
  },
};
</script>
