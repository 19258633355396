<template>
  <div>
    <PerformanceFilterBarState
      :keywordDatas="KEYWORD_DATAS"
      :defaultKeywordSelectedId="defaultKeywordSelectedId"
      :defaultKeywordInputText="defaultKeywordInputText"
      :statusList="statusList"
      :defaultStateId="defaultStateId"
      :defaultDates="defaultDates"
      @onClickSearch="onClickSearch"
    />

    <!--<TableHead>
      <template v-slot:headRight>
        
        <SelectBoxPageRange
          :currentMaxRowCount.sync="size"
          @update:currentMaxRowCount="onChangeRowCount"
        />
      </template>
    </TableHead>-->
    <TableHead>
      <template v-slot:headRight>
        <div class="group_form">
          <!-- <strong class="tit_util">정산정보</strong>
            <SelectBox
                class="w140"
                :dataList="calculateRangeList"
                :value.sync="calculateRange"
                :isDisabled="isNoDataList"
                @onChange="onChangeCalculateRange" /> -->
          <!-- <span class="bar_view"></span> -->

          <SelectBoxPageRange
            :currentMaxRowCount.sync="size"
            @update:currentMaxRowCount="onChangeRowCount"
          />
        </div>
      </template>
    </TableHead>
    <ListPage
      class="tbl_list_md"
      :list="dataList"
      :currentPageIndex="currentPageIndex"
      :totalPageCount="totalPageCount"
      :maxRowCount="size"
      :maxPaginationCount="maxPaginationCount"
      @onChangePage="onChangePage"
    >
      <template v-slot:colgroup>
        <col style="width: 115px" />
        <!-- 진행상태 -->
        <col style="width: 80px" />
        <!-- 계약구분 -->
        <col style="width: 150px" />
        <!-- 분류 -->
        <col style="width: 110px" />
        <!-- 구매요청자 -->
        <col style="width: 110px" />
        <!-- 구매 담당자 -->
        <col style="width: 346px" />
        <!-- 계약명 -->
        <col style="width: 130px" />
        <!-- 파트너사 -->
        <col style="width: 110px" />
        <!-- 검수담당자 -->
        <col style="width: 89px" />
        <!-- 평가완료 일자 -->
      </template>
      <template v-slot:tr>
        <th>진행상태</th>
        <th>계약구분</th>
        <th>분류</th>
        <th>구매요청자</th>
        <th>구매 담당자</th>
        <th>계약명</th>
        <th>파트너사</th>
        <th>검수담당자</th>
        <th>평가완료 일자</th>
      </template>
      <template v-slot:row="slotProps">
        <!-- <PerformanceListStateLine :rowData="slotProps.rowData"
          :statusList="statusList"
          @onChangeStatus="onChangeStatus" /> -->
        <PerformanceListStateLine
          :rowData="slotProps.rowData"
          @onClickPerformance="onClickPerformance"
        />
      </template>
      <template v-slot:emptyList>
        <tr>
          <td colspan="9" class="td_empty">수행도평가 현황이 없습니다</td>
        </tr>
      </template>
    </ListPage>
  </div>
</template>

<script>
import ListPage from "@/components/shared/ListPage";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import SelectBox from "@/components/common/selectBox/SelectBox";

import { mapState } from "vuex";
//import { GET_PARTNER_STATUS_LIST } from '@/store/modules/partner/action'
// import { GET_CONTRACT_LIST } from '@/store/modules/contract/action'

import PerformanceListStateLine from "./PerformanceListStateLine";
import PerformanceFilterBarState from "./PerformanceFilterBarState";

import PageMixin from "@/mixins/PageMixin";
import ListPageMixin from "@/mixins/ListPageMixin";

import { GET_EVALUATE_PROGRESS_LIST } from "@/store/modules/performance/action";
import { dateToString } from "@/utils/dateUtils";

export default {
  name: "PerformanceListState",
  components: {
    ListPage,
    TableHead,
    SelectBox,
    SelectBoxPageRange,
    PerformanceListStateLine,
    PerformanceFilterBarState,
  },
  mixins: [PageMixin, ListPageMixin],
  data() {
    return {
      API_PATH: this.$apiPath.EVALUATE,

      // calculateRange: '0',
      // isNoDataList: false,
      defaultStateId: "",
      KEYWORD_DATAS: [
        { id: "description", text: "계약명" },
        { id: "purchaseManager", text: "구매담당자" },
        { id: "inspectManager", text: "현업담당자" },
      ],
      defaultKeywordSelectedId: "description",
    };
  },
  beforeMount() {
    const params = this.checkUrlQuery();

    this.$store.dispatch(GET_EVALUATE_PROGRESS_LIST).then(() => {
      this.getDataInit(params);
    });
  },
  computed: {
    ...mapState({
      statusList: (state) => state.performance.statusList,
    }),
  },
  methods: {
    onClickPerformance(rowData) {
      const { evaluateNum } = rowData;
      this.$router.push({
        path: `${this.$routerPath.PERFORMANCE_VIEW}/${evaluateNum}`,
      });
    },
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.KEYWORD_DATAS);

      const objStatus = this.checkQueryStatus(query);

      const objDates = this.checkQueryDates(query, "regDate1", "regDate2");

      let objCateNum = {};
      const { category } = query;

      if (category) {
        objCateNum.category = category;
      }

      return Object.assign({}, objPaging, objKeyword, objStatus, objDates, objCateNum);
    },
    getFilterbarParams(objData) {
      const {
        keywordSelectedId, // 검색어 셀렉트박스
        keywordInputText, // 검색어
        startDt, // 시작날짜
        endDt, // 종료일
        status, // 진행상태
        dateSelectedId, // 평가일자 셀렉트 버튼 아이디
        category0,
        category1,
        category2, // 분류
      } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      const category = category2 || category1 || category0;

      if (category) {
        params.category = category;
      }

      params.status = status;
      params.tab = "present";

      if (startDt) {
        params.regDate1 = startDt;
      }

      if (endDt) {
        params.regDate2 = endDt;
        // params.regDate2 = dateToString(endDt);
      }

      return params;
    },
  },
};
</script>
