<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">업체(평가)관리</h2>
      </div>
      <div class="body_section">
        <Tabs
          :keyParam="KEY_PARAM"
          :dataList="TAB_DATA_LIST"
          :currentValue.sync="currentTabValue"
          @onChangeTabValue="onChangeTabValue"
        />

        <SystemPerformanceFilterBarResult
          :isShowStatus="false"
          :keywordDatas="KEYWORD_DATAS"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          @onClickSearch="onClickSearch"
        />
        <!--        :defaultScoreInputText="defaultScoreInputText"-->
        <!--        :defaultScoreSelectedId="defaultScoreSelectedId"-->

        <TableHead>
          <template v-slot:headRight>
            <SelectBoxPageRange
              :currentMaxRowCount.sync="size"
              @update:currentMaxRowCount="onChangeRowCount"
            />
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount || 0"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <col style="width: 200px" />
            <!-- 상호 -->
            <col style="width: 200px" />
            <!-- 사업자 번호 -->
            <col style="width: 120px" />
            <!-- 총 계약 건수 -->
            <col style="width: 120px" />
            <!-- 수행도평가 진행건수 -->
            <col style="width: 120px" />
            <!-- 수행도평가 완료건수 -->
            <col style="width: 120px" />
            <!-- 평균 협업 평가점수 -->
            <col style="width: 120px" />
            <!-- 평균 구매 평가점수 -->
            <col style="width: 130px" />
            <!-- 업체별 수행도 평가 평균 -->
            <col style="width: 120px" />
            <!-- 평균 총점 -->
          </template>
          <template v-slot:tr>
            <th>상호</th>
            <th>사업자 번호</th>
            <th>총 계약 건수</th>
            <th>수행도평가 진행건수</th>
            <th>수행도평가 완료건수</th>
            <th>평균 협업 평가점수</th>
            <th>평균 구매 평가점수</th>
            <th>업체별 수행도평가 평균</th>
            <th>평균 총점</th>
          </template>
          <template v-slot:row="slotProps">
            <SystemPerformanceResultListLine :rowData="slotProps.rowData" />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="9" class="td_empty">검색 결과가 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import Tabs from "@/components/common/tabs/Tabs";
import PerformanceFilterBarState from "@/components/admin/performance/list/PerformanceFilterBarState";
import PerformanceFilterBarResult from "@/components/admin/performance/list/PerformanceFilterBarResult";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import PerformanceListState from "@/components/admin/performance/list/PerformanceListState";
import PerformanceListResult from "@/components/admin/performance/list/PerformanceListResult";
import SystemPerformanceMixin from "@/components/admin/system/performance/SystemPerformanceMixin";
import ListPage from "@/components/shared/ListPage";
import ListPageMixin from "@/mixins/ListPageMixin";
import SystemPerformanceFilterBarResult from "@/components/admin/system/performance/SystemPerformanceFilterBarResult";
import SystemPerformanceResultListLine from "@/components/admin/system/performance/SystemPerformanceResultListLine";

export default {
  name: "SystemPerformanceResultList",
  components: {
    SystemPerformanceResultListLine,
    SystemPerformanceFilterBarResult,
    ListPage,
    PageWithLayout,
    Tabs,
    PerformanceFilterBarState,
    PerformanceFilterBarResult,
    TableHead,
    SelectBoxPageRange,
    PerformanceListState,
    PerformanceListResult,
  },
  mixins: [SystemPerformanceMixin, ListPageMixin],
  data() {
    return {
      currentTabValue: "SYSTEM_PERFORMANCE_RESULT",
      API_PATH: this.$apiPath.ST_EVALUATE_EVALUATE_RESULT,

      KEYWORD_DATAS: [
        { id: "companyName", text: "상호" },
        { id: "corporateNum", text: "사업자 번호" },
      ],
      defaultKeywordSelectedId: "companyName",
      // defaultScoreSelectedId: 'U',
      // defaultScoreInputText: '',
    };
  },
  beforeMount() {
    const params = this.checkUrlQuery();

    this.getDataInit(params);
  },
  methods: {
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.KEYWORD_DATAS);

      let obj = {};

      return Object.assign({}, objPaging, objKeyword, obj);
    },
    getFilterbarParams(objData) {
      const { keywordSelectedId, keywordInputText } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      return params;
    },
  },
};
</script>
