<template>
  <ListRow>
    <td>
      <button
        class="link_subject align_center"
        @click="$windowOpen(toPath)"
        v-html="rowData.companyName"
      />
    </td>
    <td>{{ rowData.corporateNum }}</td>
    <td>{{ rowData.allCnt }}</td>
    <td>{{ rowData.progressCnt }}</td>
    <td>{{ rowData.completeCnt }}</td>
    <td>{{ rowData.inspectAvg }}</td>
    <td>{{ rowData.purchaseAvg }}</td>
    <td>{{ rowData.allAvg }}</td>
  </ListRow>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import SelectBox from "@/components/common/selectBox/SelectBox";
import ListLineMixin from "@/mixins/ListLineMixin";
import { makePathWithQuery } from "@/utils/urlUtils";

export default {
  name: "PartnerListApprovalLine",
  components: {
    ListRow,
    SelectBox,
  },
  mixins: [ListLineMixin],
  computed: {
    toPath() {
      const { userNum } = this.rowData;
      const path = makePathWithQuery(this.$routerPath.PARTNER_VIEW, userNum, this.$route.query);
      return path;
    },
  },
};
</script>
