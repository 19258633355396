<template>
  <div>
    <PerformanceFilterBarResult
      :isShowStatus="false"
      :keywordDatas="KEYWORD_DATAS"
      :defaultKeywordSelectedId="defaultKeywordSelectedId"
      :defaultKeywordInputText="defaultKeywordInputText"
      :defaultScoreInputText="defaultScoreInputText"
      :defualtScoreSelectedId="defualtScoreSelectedId"
      @onClickSearch="onClickSearch"
    />

    <TableHead>
      <template v-slot:headRight>
        <SelectBoxPageRange
          :currentMaxRowCount.sync="size"
          @update:currentMaxRowCount="onChangeRowCount"
        />
      </template>
    </TableHead>
    <ListPage
      :list="dataList"
      :currentPageIndex="currentPageIndex"
      :totalPageCount="totalPageCount"
      :maxRowCount="size"
      :maxPaginationCount="maxPaginationCount"
      @onChangePage="onChangePage"
    >
      <template v-slot:colgroup>
        <col style="width: 240px" />
        <!-- 상호 -->
        <col style="width: 220px" />
        <!-- 사업자 번호 -->
        <col style="width: 130px" />
        <!-- 총 계약 건수 -->
        <col style="width: 130px" />
        <!-- 수행도평가 진행건수 -->
        <col style="width: 130px" />
        <!-- 수행도평가 완료건수 -->
        <col style="width: 130px" />
        <!-- 평균 협업 평가점수 -->
        <col style="width: 130px" />
        <!-- 평균 구매 평가점수 -->
        <col style="width: 130px" />
        <!-- 평균 총점 -->
      </template>
      <template v-slot:tr>
        <th>상호</th>
        <th>사업자 번호</th>
        <th>총 계약 건수</th>
        <th>수행도평가 진행건수</th>
        <th>수행도평가 완료건수</th>
        <th>평균 협업 평가점수</th>
        <th>평균 구매 평가점수</th>
        <th>평균 총점</th>
      </template>
      <template v-slot:row="slotProps">
        <PerformanceListResultLine :rowData="slotProps.rowData" />
      </template>
      <template v-slot:emptyList>
        <tr>
          <td colspan="8" class="td_empty">수행도평가 결과가 없습니다</td>
        </tr>
      </template>
    </ListPage>
  </div>
</template>

<script>
import ListPage from "@/components/shared/ListPage";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";

import PerformanceListResultLine from "./PerformanceListResultLine";
import { mapState } from "vuex";
//import { GET_PARTNER_STATUS_LIST } from '@/store/modules/partner/action'

import PerformanceFilterBarResult from "./PerformanceFilterBarResult";

import ListPageMixin from "@/mixins/ListPageMixin";

export default {
  components: {
    ListPage,
    TableHead,
    SelectBoxPageRange,
    PerformanceListResultLine,
    PerformanceFilterBarResult,
  },
  mixins: [ListPageMixin],
  data() {
    return {
      API_PATH: this.$apiPath.EVALUATE_RESULT,

      KEYWORD_DATAS: [
        { id: "companyName", text: "상호" },
        { id: "corporateNum", text: "사업자 번호" },
        { id: "searchWord", text: "추가검색" },
      ],
      defaultKeywordSelectedId: "companyName",
      defualtScoreSelectedId: "U",
      defaultScoreInputText: "",
      // defualtScoreSelectedIdD: "D",
    };
  },
  beforeMount() {
    const params = this.checkUrlQuery();

    this.getDataInit(params);
  },
  methods: {
    // 주소 기준으로 현재 페이지에 필요한 params 가져온다.
    // 주소 기준으로 필터바 초기값을 셋팅한다.
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.KEYWORD_DATAS);

      let scoreObj = {};
      if (query.score) scoreObj.score = query.score;
      if (query.scoreCondition) scoreObj.scoreCondition = query.scoreCondition;

      return Object.assign({}, objPaging, objKeyword, scoreObj);
    },
    getFilterbarParams(objData) {
      const { keywordSelectedId, keywordInputText, score, scoreCondition } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      params.tab = "result";

      if (score) {
        params.score = score;
      }

      if (scoreCondition) {
        params.scoreCondition = scoreCondition;
      }

      return params;
    },
  },
};
</script>
